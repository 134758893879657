import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import React, { useState } from "react";
import Home from "./Pages/Home/Home";
import Services from "./Pages/Services/Services";
import About from "./Pages/About/About";
import Navbar from "./Components/Navbar/Navbar";
import ContactUs from "./Pages/Contactus/ContactUs";
import Theatre from "./Pages/OurSevices/Theatre/Theatre";
import Cinema from "./Pages/OurSevices/Cinema/Cinema";
import MultiRoom from "./Pages/OurSevices/MultiRoom/MultiRoom";
import CoWorking from "./Pages/OurSevices/CoWorking/CoWorking";
import MeetingRoom from "./Pages/OurSevices/MeetingRoom/MeetingRoom";
import CoffeShop from "./Pages/OurSevices/CoffeeShop/CoffeeShop";
import AppContent from "./AppContent";
import Footer from "./Components/Footer/Footer";
import GetQuotationModal from "./Components/GetQouteModal/GetQuoteModal";
import Logo  from "./imgs/dark.png"
function App() {

  return (
    <>
      {/* <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<AppContent />} />
          <Route path="services" element={<Services />} />
          <Route path="about" element={<About />} />
          <Route path="contactUs" element={<ContactUs />} />
          <Route path="theatre" element={<Theatre />} />
          <Route path="cinema" element={<Cinema />} />
          <Route path="multiRoom" element={<MultiRoom />} />
          <Route path="meetingRoom" element={<MeetingRoom />} />
          <Route path="coWorking" element={<CoWorking />} />
          <Route path="coffeeShop" element={<CoffeShop />} />
        </Routes>
        <Footer/>
 
      </BrowserRouter> */}
      <div className="flex justify-center items-center pt-12 mb-24">
        <img src={Logo} alt="" className="w-32 sm:w-48 md:w-64 lg:w-72 xl:w-96" />
      </div>
      <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-mono text-center" style={{ fontFamily: "Roboto Condensed", fontWeight: "300" }}>Our Website is Coming Soon!</h1>
    </>
  );
}

export default App;
